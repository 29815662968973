

<template>
    <div class="scanner" style="justify-content: center;">
        <img src="@/assets/QRcode.png" style="width:11.5vw;position: absolute;top:1vh;left: 1.3vw;" />
        <img src="@/assets/Apple.png" style="height:4.9vh;position: absolute;top: 25vh;left: 2vw;" />
        <h1 class="about-us-font" style="font-size: 1vw;position: absolute;top: 19vh;left: 3vw;color: black; width: 8vw;">Download from the Appstore</h1>


    </div>
</template>
<script>
export default {
    name: "ScanneriOS", 

};

</script>

<style>
.scanner{
width: 14vw;
height: 34vh;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 2px solid #000C00;
opacity: 1;
border-radius: 20px;
}
</style>