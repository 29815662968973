

<template>
    <div class="about-us" >
        <img src="@/assets/Target.png" style="height:9vh;margin-top: 4vh;" />
        <h1 class="about-us-font">TRE has tracking technology to help you better protect your shipments</h1>


    </div>
</template>
<script>
export default {
    name: "AboutUs2", 

};

</script>

<style>


</style>