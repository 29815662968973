

<template>
    <div class="about-us" >
        <!-- <img src="@/assets/ShakingHands.png" style="height:300vh; position: absolute; top: 12vh; left: 0vw;" /> -->
        <img src="@/assets/Exchange.png" style="height:9vh;margin-top: 4vh;" />
        <h1 class="about-us-font" >TRE is an open platform that provides carriers and shippers of all sizes opportunities to grow</h1>
        


    </div>
</template>
<script>
export default {
    name: "AboutUs3", 

};

</script>

<style>




</style>