

<template>
    <div class="scanner" style="justify-content: center;">
        <img src="@/assets/QRcode.png" style="width:11.5vw;position: absolute;top:1vh;left: 1.3vw; resize: both;" />
        <img src="@/assets/GooglePlay.png" style="height:4.9vh;position: absolute;top: 25vh;left: 2vw;" />
        <h1 class="about-us-font" style="font-size: 1vw;position: absolute;top: 19vh;left: 3vw;color: black; width: 8vw;">Download from Google Play Store</h1>

    </div>
</template>
<script>
export default {
    name: "ScannerGoogle", 

};

</script>

<style>


</style>