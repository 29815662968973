

<template>
    <div class="benefits-info" >
        <h1 class="benefits-font">By encouraging new users to join the TRE network, you can earn commissions generated by those individuals. Your referral will also recruit their own networks into the company, that will earn you income without you having to lift a finger!</h1>
    </div>
</template>
<script>
export default {
    name: "BenefitsInfo", 
    
};

</script>

<style>
.benefits-info{
background-color: #58c06e;
border-radius: 7vh;
opacity: 1;
height: fit-content;
width: 80vw;
margin-left: 8vw;
resize: both;

}
.benefits-font{
    text-align: center;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
/* font-size: 2vh; */
margin-left: 3vw;
margin-right: 1.5vw;
margin-top: 2vh;
font: normal normal normal 3vh Segoe UI;
}

</style>